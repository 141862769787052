import { ReactComponent as CasperWalletIcon } from "./CasperWallet.svg";
import { ReactComponent as MetaMaskIcon } from "./MetaMask.svg";
import { ReactComponent as WalletConnectIcon } from "./WalletConnect.svg";
import { ReactComponent as ActiveIcon } from "./active.svg";
import { ReactComponent as AddIcon } from "./add.svg";
import { ReactComponent as AffiliateProductsArrow } from "./affiliate-products-arrow.svg";
import { ReactComponent as AffiliateProductsDollar } from "./affiliate-products-dollar.svg";
import { ReactComponent as AffiliateProductsPercent } from "./affiliate-products-percent.svg";
import { ReactComponent as AffiliateProductsSeparator } from "./affiliate-products-separator.svg";
import { ReactComponent as AffiliateStoreInstagram } from "./affiliate-store-instagram.svg";
import { ReactComponent as AffiliateStoreTiktok } from "./affiliate-store-tiktok.svg";
import { ReactComponent as AffiliateStoreTwitter } from "./affiliate-store-twitter.svg";
import { ReactComponent as AffiliateStoreYoutube } from "./affiliate-store-youtube.svg";
import { ReactComponent as ArrowDown } from "./arrowDown.svg";
import { ReactComponent as AscSorting } from "./asc-sorting.svg";
import { ReactComponent as BackArrow } from "./back-arrow.svg";
import { ReactComponent as Base } from "./base-logo.svg";
import { ReactComponent as Binance } from "./binance.svg";
import { ReactComponent as Bitcoin } from "./bitcoin.svg";
import { ReactComponent as BlackChevronDown } from "./black-chevron-down.svg";
import { ReactComponent as BlackCircleI } from "./black-circle-i.svg";
import { ReactComponent as BlackInformation } from "./black-information.svg";
import { ReactComponent as BlackPlus } from "./black-plus.svg";
import { ReactComponent as Blog } from "./blog.svg";
import { ReactComponent as BlueBase } from "./blue-base.svg";
import { ReactComponent as BlueLinea } from "./blue-linea.svg";
import { ReactComponent as BluePlus } from "./blue-plus.svg";
import { ReactComponent as Brain } from "./brain.svg";
import { ReactComponent as CasperIcon } from "./casper.svg";
import { ReactComponent as CheckCircle } from "./check-circle.svg";
import { ReactComponent as CircleCheck } from "./circle-check.svg";
import { ReactComponent as CircleCopy } from "./circle-copy.svg";
import { ReactComponent as CircleDashboard } from "./circle-dashboard.svg";
import { ReactComponent as CircleEth } from "./circle-eth.svg";
import { ReactComponent as CircleModal } from "./circle-modal.svg";
import { ReactComponent as CirclePolygon } from "./circle-polygon.svg";
import { ReactComponent as CircleRefresh } from "./circle-refresh.svg";
import { ReactComponent as CircleShield } from "./circle-shield.svg";
import { ReactComponent as CircleSolana } from "./circle-solana.svg";
import { ReactComponent as CircleUsdc } from "./circle-usdc.svg";
import { ReactComponent as CircleWithdraw } from "./circle-withdraw.svg";
import { ReactComponent as Circle } from "./circle.svg";
import { ReactComponent as Clock } from "./clock.svg";
import { ReactComponent as CloseRed } from "./close-red.svg";
import { ReactComponent as Close } from "./close.svg";
import { ReactComponent as Coinbase } from "./coinbase.svg";
import { ReactComponent as CollectionIcon } from "./collection-icon.svg";
import { ReactComponent as ConfirmPlan } from "./confirm-plan.svg";
import { ReactComponent as Copy } from "./copy.svg";
import { ReactComponent as Cover } from "./cover.svg";
import { ReactComponent as D3Access } from "./d3-access.svg";
import { ReactComponent as D3CoreChain } from "./d3-core-chain.svg";
import { ReactComponent as D3D3BorderRed } from "./d3-d3-border-red.svg";
import { ReactComponent as D3D3Border } from "./d3-d3-border.svg";
import { ReactComponent as D3DroplinkedBorderRed } from "./d3-droplinked-border-red.svg";
import { ReactComponent as D3DroplinkedBorder } from "./d3-droplinked-border.svg";
import { ReactComponent as D3EnterpriseTools } from "./d3-enterprise-tools.svg";
import { ReactComponent as D3Logo } from "./d3-logo.svg";
import { ReactComponent as D3Main } from "./d3-main.svg";
import { ReactComponent as D3NFTIntegration } from "./d3-nft-integration.svg";
import { ReactComponent as D3ProPlan } from "./d3-pro-plan.svg";
import { ReactComponent as D3Shiba } from "./d3-shiba.svg";
import { ReactComponent as D3ShopSetup } from "./d3-shop-setup.svg";
import { ReactComponent as D3Spinner } from "./d3-spinner.svg";
import { ReactComponent as D3Tick } from "./d3-tick.svg";
import { ReactComponent as D3Victorin } from "./d3-victorin.svg";
import { ReactComponent as D3Wallet } from "./d3-wallet.svg";
import { ReactComponent as D3Web3Support } from "./d3-web3-support.svg";
import { ReactComponent as Dashboard } from "./dashboard.svg";
import { ReactComponent as DatepickerDashboard } from "./datepicker-dashboard.svg";
import { ReactComponent as DeleteIcon } from "./delete-icon.svg";
import { ReactComponent as Delete } from "./delete.svg";
import { ReactComponent as DescSorting } from "./desc-sorting.svg";
import { ReactComponent as Desktop } from "./desktop-icon.svg";
import { ReactComponent as Diamond } from "./diamond.svg";
import { ReactComponent as Discord } from "./discord.svg";
import { ReactComponent as DiscountIcon } from "./discount-active-icon.svg";
import { ReactComponent as DiscountSetting } from "./discount-setting.svg";
import { ReactComponent as Dots } from "./dots.svg";
import { ReactComponent as Download } from "./download.svg";
import { ReactComponent as Droplinked } from "./droplinked.svg";
import { ReactComponent as EditIconWhite } from "./edit-icon-white.svg";
import { ReactComponent as EditIcon } from "./edit-icon.svg";
import { ReactComponent as Edit } from "./edit.svg";
import { ReactComponent as Email } from "./email.svg";
import { ReactComponent as EnterprisePlan } from "./enterprise-plan.svg";
import { ReactComponent as ETH } from "./eth.svg";
import { ReactComponent as EventCalendar } from "./event-calendar.svg";
import { ReactComponent as EventHost } from "./event-host.svg";
import { ReactComponent as EventLocation } from "./event-location.svg";
import { ReactComponent as Eye } from "./eye.svg";
import { ReactComponent as FacebookIcon } from "./facebook.svg";
import { ReactComponent as File } from "./file.svg";
import { ReactComponent as SaveIcon } from "./frame20783.svg";
import { ReactComponent as Gamification } from "./gamification.svg";
import { ReactComponent as GatedIcon } from "./gated-active-icon.svg";
import { ReactComponent as GiftSetting } from "./gift-setting.svg";
import { ReactComponent as Google } from "./google.svg";
import { ReactComponent as GrayDollor } from "./gray-dollar.svg";
import { ReactComponent as GrayPercent } from "./gray-percent.svg";
import { ReactComponent as GrayDiscountIcon } from "./grayDiscountIcon.svg";
import { ReactComponent as GrayGatedIcon } from "./grayGatedIcon.svg";
import { ReactComponent as GreenAlert } from "./green-alert.svg";
import { ReactComponent as Hand } from "./hand.svg";
import { ReactComponent as HeaderAffiliateSass } from "./header-affiliate-sass.svg";
import { ReactComponent as HeaderAffiliate } from "./header-affiliate.svg";
import { ReactComponent as HeaderBag } from "./header-bag.svg";
import { ReactComponent as HeaderCoins } from "./header-coins.svg";
import { ReactComponent as HeaderCreditCard } from "./header-credit.svg";
import { ReactComponent as HeaderD3 } from "./header-d3.svg";
import { ReactComponent as HeaderImage } from "./header-image.svg";
import { ReactComponent as HeaderProductBox } from "./header-product-box.svg";
import { ReactComponent as HeaderShirt } from "./header-shirt.svg";
import { ReactComponent as HeaderTokenCoin } from "./header-token-coin.svg";
import { ReactComponent as Hedera } from "./hedera.svg";
import { ReactComponent as HelpCenter } from "./help-circle 1.svg";
import { ReactComponent as MinusIcon } from "./image2vector.svg";
import { ReactComponent as ImportFile } from "./import-file.svg";
import { ReactComponent as InfoIcon } from "./info-icon.svg";
import { ReactComponent as Info } from "./info.svg";
import { ReactComponent as InformationIcon } from "./information-icon.svg";
import { ReactComponent as InstagramIcon } from "./instagram.svg";
import { ReactComponent as InvisibleIcon } from "./invisible-icon.svg";
import { ReactComponent as InvoiceCreated } from "./invoice-created.svg";
import { ReactComponent as InvoiceDiscount } from "./invoice-discount.svg";
import { ReactComponent as InvoiceManagement } from "./invoice-management.svg";
import { ReactComponent as InvoiceProduct } from "./invoice-product.svg";
import { ReactComponent as LandingPageCode } from "./landing-page-code.svg";
import { ReactComponent as LandingPageCreditCard } from "./landing-page-credit-card.svg";
import { ReactComponent as LandingPageDesignNib } from "./landing-page-design-nib.svg";
import { ReactComponent as LandingPageLink } from "./landing-page-link.svg";
import { ReactComponent as LandingPageTemplate } from "./landing-page-template.svg";
import { ReactComponent as Linea } from "./linea.svg";
import { ReactComponent as LinkedIn } from "./linkedin.svg";
import { ReactComponent as Logout } from "./log-out.svg";
import { ReactComponent as ManageIcon } from "./manage.svg";
import { ReactComponent as Maximize } from "./maximize.svg";
import { ReactComponent as MetaverseStore } from "./metaverse-store.svg";
import { ReactComponent as Mobile } from "./mobile-icon.svg";
import { ReactComponent as MoreIcon } from "./more-icon.svg";
import { ReactComponent as MultiShopManagement } from "./multi-shop.svg";
import { ReactComponent as NearWalletIcon } from "./nearWallet.svg";
import { ReactComponent as NewPolygon } from "./new-polygon.svg";
import { ReactComponent as NewRedbelly } from "./new-redbelly.svg";
import { ReactComponent as NewSolana } from "./new-solana.svg";
import { ReactComponent as NewStripe } from "./new-stripe.svg";
import { ReactComponent as NotSorted } from "./not-sorted.svg";
import { ReactComponent as OrderIcon } from "./order-icon.svg";
import { ReactComponent as Order } from "./order.svg";
import { ReactComponent as Paint } from "./paint.svg";
import { ReactComponent as PaymentMethodSelection } from "./payment-method-selection.svg";
import { ReactComponent as Pen } from "./pen.svg";
import { ReactComponent as Personalize } from "./personalize.svg";
import { ReactComponent as Phantom } from "./phantom.svg";
import { ReactComponent as PintrestIcon } from "./pintrest.svg";
import { ReactComponent as PlusIcon } from "./plus-icon.svg";
import { ReactComponent as Polygon } from "./polygon-matic-logo.svg";
import { ReactComponent as PopularPlanMedal } from "./popular-plan-medal.svg";
import { ReactComponent as PremiumPlan } from "./premium-plan.svg";
import { ReactComponent as ProPlan } from "./pro-plan.svg";
import { ReactComponent as VariantsIcon } from "./products-active-icon.svg";
import { ReactComponent as ProductsIcon } from "./products-icon.svg";
import { ReactComponent as ProfileCredit } from "./profile-credit.svg";
import { ReactComponent as ProfileDocument } from "./profile-document.svg";
import { ReactComponent as ProfileEnterprise } from "./profile-enterprise.svg";
import { ReactComponent as ProfileLogout } from "./profile-logout.svg";
import { ReactComponent as ProfileSetting } from "./profile-setting.svg";
import { ReactComponent as ProfileShop } from "./profile-shop.svg";
import { ReactComponent as ProfileSupport } from "./profile-support.svg";
import { ReactComponent as ProfileSwitch } from "./profile-switch.svg";
import { ReactComponent as RedAlert } from "./red-alert.svg";
import { ReactComponent as RedCircleCross } from "./red-circle-cross.svg";
import { ReactComponent as RedTrash } from "./red-trash.svg";
import { ReactComponent as Redbelly } from "./redbelly.svg";
import { ReactComponent as Refresh } from "./refresh.svg";
import { ReactComponent as Ripple } from "./ripple.svg";
import { ReactComponent as RuleIcon } from "./rulesets-active-icon.svg";
import { ReactComponent as RulesetsIcon } from "./rulesets-icon.svg";
import { ReactComponent as Search } from "./search.svg";
import { ReactComponent as SelectChevronDown } from "./select-chevron-down.svg";
import { ReactComponent as SettingIcon } from "./setting-icon.svg";
import { ReactComponent as ShareIcon } from "./share-icon.svg";
import { ReactComponent as Share } from "./share.svg";
import { ReactComponent as ShopIcon } from "./shop-green-icon.svg";
import { ReactComponent as SidebarAffiliate } from "./sidebar-affiliate.svg";
import { ReactComponent as SidebarAnalytics } from "./sidebar-analytics.svg";
import { ReactComponent as SidebarBlog } from "./sidebar-blog.svg";
import { ReactComponent as SidebarBrush } from "./sidebar-brush.svg";
import { ReactComponent as SidebarChat } from "./sidebar-chat.svg";
import { ReactComponent as SidebarChevrondown } from "./sidebar-chevrondown.svg";
import { ReactComponent as SidebarChevronright } from "./sidebar-chevronright.svg";
import { ReactComponent as SidebarCircle } from "./sidebar-circle.svg";
import { ReactComponent as SidebarDroplinked } from "./sidebar-droplinked.svg";
import { ReactComponent as SidebarGo } from "./sidebar-go.svg";
import { ReactComponent as SidebarNext } from "./sidebar-next.svg";
import { ReactComponent as SidebarNote } from "./sidebar-note.svg";
import { ReactComponent as SidebarPlan } from "./sidebar-plan.svg";
import { ReactComponent as SidebarProducts } from "./sidebar-products.svg";
import { ReactComponent as SidebarRewards } from "./sidebar-rewards.svg";
import { ReactComponent as SidebarSetting } from "./sidebar-setting.svg";
import { ReactComponent as SidebarTickedCircle } from "./sidebar-ticked-circle.svg";
import { ReactComponent as SidebarUser } from "./sidebar-user.svg";
import { ReactComponent as Skale } from "./skale.svg";
import { ReactComponent as SnapchatIcon } from "./snapchat.svg";
import { ReactComponent as Solana } from "./solana.svg";
import { ReactComponent as StarOff } from "./star-off.svg";
import { ReactComponent as StarOn } from "./star-on.svg";
import { ReactComponent as StarFilled } from "./starFilled.svg";
import { ReactComponent as StarOutline } from "./starOutline.svg";
import { ReactComponent as StarterPlan } from "./starter-plan.svg";
import { ReactComponent as StripePayment } from "./stripe-payment.svg";
import { ReactComponent as StripeS } from "./stripe-s-icon.svg";
import { ReactComponent as Stripe } from "./stripe.svg";
import { ReactComponent as Stacks } from "./stx.svg";
import { ReactComponent as SubscriptionPlan } from "./subscription-plan.svg";
import { ReactComponent as TearIcon } from "./tear-icon.svg";
import { ReactComponent as ThreeStars } from "./three-stars.svg";
import { ReactComponent as Tick } from "./tick.svg";
import { ReactComponent as TikTok } from "./tiktok.svg";
import { ReactComponent as Token } from "./token.svg";
import { ReactComponent as TrashRed } from "./trash-red.svg";
import { ReactComponent as Trust } from "./trust.svg";
import { ReactComponent as TwitterIcon } from "./twitter.svg";
import { ReactComponent as Unisat } from "./unisat.svg";
import { ReactComponent as Unlock } from "./unlock.svg";
import { ReactComponent as UnstoppableDomainsIcon } from "./unstoppableDomains.svg";
import { ReactComponent as Upload } from "./upload-icon.svg";
import { ReactComponent as UserInvitation } from "./user-invitation.svg";
import { ReactComponent as VisibleIcon } from "./visible-icon.svg";
import { ReactComponent as Web } from "./web.svg";
import { ReactComponent as NFT } from "./white-nft-icon.svg";
import { ReactComponent as WhiteOpenWallet } from "./white-open-wallet.svg";
import { ReactComponent as WhiteShopIcon } from "./white-shop.svg";
import { ReactComponent as WhiteTrash } from "./white-trash.svg";
import { ReactComponent as XRPL } from "./xrpl.svg";
import { ReactComponent as Xumm } from "./xumm.svg";
import { ReactComponent as Xverse } from "./xverse.svg";
import { ReactComponent as YellowAlert } from "./yellow-alert.svg";

const AppIcons = {
    ProductsIcon,
    CollectionIcon,
    SettingIcon,
    OrderIcon,
    InformationIcon,
    RulesetsIcon,
    InvisibleIcon,
    VisibleIcon,
    AddIcon,
    VariantsIcon,
    Xverse,
    RuleIcon,
    TrashRed,
    EditIcon,
    DeleteIcon,
    ShopIcon,
    InstagramIcon,
    FacebookIcon,
    TwitterIcon,
    PintrestIcon,
    SnapchatIcon,
    MetaMaskIcon,
    SaveIcon,
    CasperIcon,
    CasperWalletIcon,
    WalletConnectIcon,
    NearWalletIcon,
    UnstoppableDomainsIcon,
    MoreIcon,
    DiscountIcon,
    GatedIcon,
    PlusIcon,
    MinusIcon,
    Solana,
    Phantom,
    TearIcon,
    InfoIcon,
    ManageIcon,
    ActiveIcon,
    ArrowDown,
    Close,
    Stacks,
    Info,
    Bitcoin,
    Stripe,
    File,
    EditIconWhite,
    Discord,
    Web,
    LinkedIn,
    TikTok,
    Cover,
    Upload,
    Polygon,
    Google,
    DiscountSetting,
    GiftSetting,
    Ripple,
    StarOutline,
    StarFilled,
    Binance,
    Email,
    Base,
    Hand,
    Dashboard,
    DatepickerDashboard,
    Linea,
    WhiteShopIcon,
    RedAlert,
    WhiteOpenWallet,
    HelpCenter,
    Logout,
    Coinbase,
    NFT,
    Unisat,
    Droplinked,
    Hedera,
    Skale,
    Xumm,
    ETH,
    GrayGatedIcon,
    GrayDiscountIcon,
    RedTrash,
    XRPL,
    GreenAlert,
    YellowAlert,
    StripeS,
    Order,
    BluePlus,
    WhiteTrash,
    StarOff,
    StarOn,
    Personalize,
    Brain,
    Unlock,
    Trust,
    Diamond,
    ThreeStars,
    CheckCircle,
    UserInvitation,
    ImportFile,
    BlackInformation,
    RedCircleCross,
    CircleCheck,
    GrayDollor,
    BlackCircleI,
    GrayPercent,
    BlackChevronDown,
    BackArrow,
    Blog,
    CloseRed,
    Clock,
    Pen,
    Delete,
    Tick,
    SubscriptionPlan,
    MultiShopManagement,
    EventCalendar,
    EventLocation,
    EventHost,
    Gamification,
    Paint,
    Redbelly,
    Eye,
    Maximize,
    Mobile,
    Desktop,
    Refresh,
    Download,
    Share,
    StarterPlan,
    ProPlan,
    PremiumPlan,
    EnterprisePlan,
    ConfirmPlan,
    Token,
    NewPolygon,
    NewRedbelly,
    NewSolana,
    NewStripe,
    BlueBase,
    BlueLinea,
    PaymentMethodSelection,
    StripePayment,
    PopularPlanMedal,
    InvoiceManagement,
    BlackPlus,
    Dots,
    Search,
    InvoiceDiscount,
    InvoiceCreated,
    Copy,
    SelectChevronDown,
    InvoiceProduct,
    NotSorted,
    AscSorting,
    DescSorting,
    Edit,
    SidebarDroplinked,
    SidebarAffiliate,
    SidebarAnalytics,
    SidebarBlog,
    SidebarProducts,
    SidebarChat,
    SidebarChevrondown,
    SidebarNote,
    SidebarPlan,
    SidebarRewards,
    SidebarSetting,
    SidebarUser,
    ProfileCredit,
    ProfileDocument,
    ProfileEnterprise,
    ProfileLogout,
    ProfileSetting,
    ProfileShop,
    ProfileSupport,
    ProfileSwitch,
    AffiliateProductsArrow,
    AffiliateProductsSeparator,
    AffiliateProductsDollar,
    AffiliateProductsPercent,
    ShareIcon,
    SidebarBrush,
    AffiliateStoreInstagram,
    AffiliateStoreTwitter,
    AffiliateStoreYoutube,
    AffiliateStoreTiktok,
    LandingPageLink,
    LandingPageDesignNib,
    LandingPageCreditCard,
    LandingPageCode,
    LandingPageTemplate,
    SidebarCircle,
    SidebarTickedCircle,
    SidebarChevronright,
    SidebarNext,
    SidebarGo,
    D3Main,
    D3Spinner,
    D3Wallet,
    D3Tick,
    D3ShopSetup,
    D3EnterpriseTools,
    D3NFTIntegration,
    D3Access,
    D3ProPlan,
    D3Web3Support,
    D3Logo,
    D3DroplinkedBorder,
    D3D3Border,
    D3DroplinkedBorderRed,
    D3D3BorderRed,
    Circle,
    CircleShield,
    CircleDashboard,
    CircleRefresh,
    CircleCopy,
    D3Shiba,
    D3Victorin,
    D3CoreChain,
    HeaderAffiliate,
    HeaderAffiliateSass,
    HeaderBag,
    HeaderCoins,
    HeaderCreditCard,
    HeaderImage,
    HeaderShirt,
    HeaderTokenCoin,
    HeaderD3,
    HeaderProductBox,
    MetaverseStore,
    CircleModal,
    CircleUsdc,
    CircleEth,
    CircleSolana,
    CirclePolygon,
    CircleWithdraw,
};

export default AppIcons;

import { Flex, useDisclosure, useRadioGroup } from '@chakra-ui/react'
import { useFormikContext } from 'formik'
import useAppToast from 'functions/hooks/toast/useToast'
import { addAddressToCartService, createAddressService } from 'lib/apis/invoice/invoiceServices'
import { deepEqual } from 'lib/utils/helpers/helpers'
import { InvoiceFormSchema } from 'pages/invoice-management/create-invoice/helpers/helpers'
import useCreateInvoice from 'pages/invoice-management/create-invoice/hooks/useCreateInvoice'
import useInvoiceStore from 'pages/invoice-management/create-invoice/store/invoiceStore'
import React, { useEffect, useState } from 'react'
import ToggleableSection from '../../ToggleableSection'
import ShippingMethodsLoading from './ShippingMethodsLoading'
import ShippingMethodRadio from './ShippingMethodRadio'

export default function InvoiceShippingMethods() {
    const [isLoading, setLoading] = useState(false)
    const { isOpen, onOpen, onClose } = useDisclosure()
    const { values, validateForm } = useFormikContext<InvoiceFormSchema>()
    const { isInvoiceDataValid } = useCreateInvoice({ trigger: "SHIPPING_METHODS_SWITCH" })
    const { cart, updateCart, selectedShippingMethod, updateShippingMethod, isEditMode } = useInvoiceStore()
    const { showToast } = useAppToast()
    const { getRootProps, getRadioProps } = useRadioGroup({
        name: 'selected-payment-method',
        onChange: (shppingMethodId: string) => {
            const shippingGroup = cart.shippings.find(group => group.data.some(method => method.id === shppingMethodId))
            updateShippingMethod({ groupId: shippingGroup.groupId, shipmentId: shppingMethodId })
        },
        value: selectedShippingMethod?.shipmentId,
    })

    const handleToggle = async () => {
        const validationResult = await validateForm()
        if (Object.entries(validationResult).length > 0) return
        if (!isInvoiceDataValid(values)) return
        onOpen()
    }

    const renderContent = () => {
        if (isLoading) return <ShippingMethodsLoading />
        if (!cart.shippings?.length) return null
        return (
            <Flex direction="column" gap={4} {...getRootProps()}>
                {cart?.shippings.map((shippingGroup) => (
                    shippingGroup.data.map((shippingMethod) => (
                        <ShippingMethodRadio
                            key={shippingMethod.id}
                            method={shippingMethod}
                            {...getRadioProps({ value: shippingMethod.id })}
                        />
                    ))
                ))}
            </Flex>
        )
    }

    useEffect(() => {
        (async () => {
            const { _id, easyPostAddressID, ...rest } = cart.address ?? {}

            if ((isEditMode && !deepEqual(rest, values.address)) || (isOpen && !cart.address)) {
                try {
                    setLoading(true)
                    const { data: createdAddress } = await createAddressService(values.address)
                    const { data } = await addAddressToCartService(cart._id, createdAddress._id)
                    updateCart(data)
                    updateShippingMethod(null)
                }
                catch (error) {
                    if (error.response) showToast({ message: error.response.data.data.message, type: "error" })
                    else showToast({ message: (error as Error).message, type: "error" })
                    onClose()
                }
                finally {
                    setLoading(false)
                }
            }
        })()
    }, [isOpen])

    useEffect(() => {
        const { _id, easyPostAddressID, ...rest } = cart.address ?? {}
        if (!deepEqual(rest, values.address)) onClose()
    }, [values.address])

    return (
        <ToggleableSection
            title='Shipping'
            description='Shipping methods are based on the type of inventory and address on the invoice.'
            isExpanded={isOpen}
            onToggle={isOpen ? onClose : handleToggle}
        >
            {renderContent()}
        </ToggleableSection>
    )
}